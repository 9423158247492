var ScrollableRow=document.querySelector('#ScrollableRow')
var ele=ScrollableRow
var pos = { top: 0, left: 0, x: 0, y: 0 }

window['mouseDownHandler']=function mouseDownHandler(e) {
  pos = {
    // The current scroll
    left: ele.scrollLeft,
    top: ele.scrollTop,
    // Get the current mouse position
    x: e.clientX,
    y: e.clientY,
  }

  ele.style.cursor = 'grabbing'
  // ele.style.userSelect = 'none'

  document.addEventListener('mousemove', mouseMoveHandler);
  document.addEventListener('mouseup', mouseUpHandler);
}

const mouseMoveHandler = function (e) {
  // How far the mouse has been moved
  const dx = e.clientX - pos.x;
  const dy = e.clientY - pos.y;

  // Scroll the element
  ele.scrollTop = pos.top - dy;
  ele.scrollLeft = pos.left - dx;
}

const mouseUpHandler = function () {
  document.removeEventListener('mousemove', mouseMoveHandler);
  document.removeEventListener('mouseup', mouseUpHandler);

  ele.style.cursor = 'grab';
  // ele.style.removeProperty('user-select');
}